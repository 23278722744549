<template>
  <section>
    <v-container class="pa-2 pa-sm-12">
      <app-title>Calendrier de recueil des données</app-title>
      <app-calendar type="month"></app-calendar>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'CalendarSection',

  components: {
    'app-calendar': () => import('../../../core/Calendar'),
    'app-title': () => import('../../../core/Title')
  }
}
</script>
